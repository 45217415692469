import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Preloader = _resolveComponent("Preloader")!
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), null, {
    default: _withCtx(() => [
      _createVNode(_component_router_view),
      _createVNode(_component_Preloader),
      _createVNode(_component_Notification)
    ]),
    _: 1
  }))
}