import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/setting",
        name: "SIMInfo",
        component: () => import("@/views/Settings/SettingsMainPage.vue"),
        meta: {
            title: "SIM info - Settings | WSC",
        },
    },
    {
        path: "/setting/security",
        name: "Security",
        component: () => import("@/views/Settings/SettingsSecurityPage.vue"),
        meta: {
            title: "Security settings - Settings | WSC",
        },
    },
    {
        path: "/setting/privacy",
        name: "Terms",
        component: () => import("@/views/Settings/SettingsTermsPage.vue"),
        meta: {
            title: "Privacy - Settings | WSC",
        },
    },
];

export default routes;
