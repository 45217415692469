
import { Vue } from "vue-class-component";

export default class MobileHeader extends Vue {
    showHeader() {
        (window as any).header.Show();
    }

    SwitchLang() {
        let lang = localStorage.getItem("lang");
        if (lang) {
            switch (lang) {
                case "en":
                    localStorage.setItem("lang", "it");
                    this.$i18n.locale = "it";
                    break;
                case "it":
                    localStorage.setItem("lang", "en");
                    this.$i18n.locale = "en";
                    break;
            }
        }
    }
}
