import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/support",
        name: "Support",
        component: () => import("@/views/Support/SupportPage.vue"),
        meta: {
            title: "FAQ - Support | WSC",
        },
    },
    {
        path: "/support/documents",
        name: "Documents",
        component: () => import("@/views/Support/DocumentsPage.vue"),
        meta: {
            title: "Documents - Support | WSC",
        },
    },
    {
        path: "/support/information",
        name: "Information",
        component: () => import("@/views/Support/InfoPage.vue"),
        meta: {
            title: "Information - Support | WSC",
        },
    },
];

export default routes;
