
import { Vue, Options } from "vue-class-component";

@Options({
    props: {
        title: {
            type: String,
            default: "",
        },
        description: {
            type: String,
            default: "",
        },
        showPopup: {
            type: Boolean,
            default: false,
        },
        hide: {
            type: Function,
            required: false,
        },
    },
})
export default class InformationPopup extends Vue {
    public showPopup!: boolean;

    Hide() {
        this.showPopup = false;
    }
}
