import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40d0aa88"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "hit",
  class: "hit-message"
}
const _hoisted_2 = { class: "hit hit-message-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["hit-wrapper", {
                show: _ctx.showNotification,
                danger: _ctx.typeNotification === 'danger',
                success: _ctx.typeNotification === 'success',
            }])
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.textNotification), 1)
    ], 2)
  ]))
}