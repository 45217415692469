import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/i18n/index";
import Header from "@/components/Header.vue";
import MobileHeader from "@/components/MobileHeader.vue";
import Default from "@/views/Layouts/Default.vue";
import Auth from "@/views/Layouts/Auth.vue";
import TopupLayout from "@/views/Layouts/TopupLayout.vue";
import LottieAnimation from "lottie-web-vue";

createApp(App).use(LottieAnimation).component("default-layout", Default).component("topup-layout", TopupLayout).component("auth-layout", Auth).component("Header", Header).component("MobileHeader", MobileHeader).use(i18n).use(store).use(router).mount("#app");
