import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/offers",
        component: () => import("@/views/OffersPage.vue"),
        meta: {
            title: "Offers | WSC",
        },
    },
    {
        path: "/offers/success",
        component: () => import("@/views/Status/SuccessStatus.vue"),
        meta: {
            title: "Offers | WSC",
            layout: "topup-layout",
        },
    },
    {
        path: "/offers/failed",
        component: () => import("@/views/Status/FailedStatus.vue"),
        meta: {
            title: "Offers | WSC",
            layout: "topup-layout",
        },
    },
];

export default routes;
