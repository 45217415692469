
import { Vue, Options } from "vue-class-component";

@Options({
    props: {
        name: {
            type: String,
            default: "",
        },
    },
})
export default class InformationActionsBtn extends Vue {}
