import { createStore } from "vuex";
import ApiApp from "@/api/ApiApp";

export default createStore({
    state: {
        mainOffer: null,
    },
    getters: {
        getMainOffer(state) {
            return state.mainOffer;
        },
    },
    mutations: {
        setMainOfferData(state, data) {
            state.mainOffer = data;
        },
    },
    actions: {
        async getMainOffer(context) {
            const response = await ApiApp.GetSubscriptionsData();
            if (response.success) {
                const offers_list_step_one = response.data.options.filter((item: any) => item.type === "Option" && !item.dependantOptionIds.length && (item.statusId === 1 || item.statusId === 2 || item.statusId === 5 || item.statusId === 6 || item.statusId === 7));
                const offer = offers_list_step_one.find((item: any) => {
                    if (item.name.toLowerCase() != "daily internet") {
                        const isHiddenOptions = response.data.mvnoConfig.optionsConfig.hiddenOptionIds.find((el: number) => el === item.id);
                        if (isHiddenOptions) return false;
                        return true;
                    }
                });
                if (offer) {
                    context.commit("setMainOfferData", offer);
                }
            }
        },
    },
    modules: {},
});
