import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/recharge",
        name: "RecurringPage",
        component: () => import("@/views/Recurring/RecurringPage.vue"),
        meta: {
            title: "Automatic recharge | WSC",
        },
    },
    {
        path: "/recharge/start-failed",
        name: "RecurringStartFailed",
        component: () => import("@/views/Recurring/StartRecurringFailed.vue"),
        meta: {
            title: "Failed | WSC",
            layout: "topup-layout",
        },
    },
    {
        path: "/recharge/success",
        name: "RecurringSuccess",
        component: () => import("@/views/Recurring/RecurringSuccessPage.vue"),
        meta: {
            title: "Success | WSC",
            layout: "topup-layout",
        },
    },
    {
        path: "/recurring/failed",
        name: "RecurringFailed",
        component: () => import("@/views/Recurring/RecurringErrorPage.vue"),
        meta: {
            title: "Failed | WSC",
            layout: "topup-layout",
        },
    },
];

export default routes;
