
import "@/styles/index.less";
import { Vue, Options } from "vue-class-component";
import Preloader from "@/components/Preloader.vue";
import Notification from "@/components/Notification.vue";
import axios from "axios";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/it";

@Options({
    components: {
        Preloader,
        Notification,
    },
})
export default class App extends Vue {
    get layout() {
        return this.$route.meta.layout || "default-layout";
    }

    created() {
        this.checkLang();
        axios.interceptors.response.use(
            config => {
                if (config.request.responseURL.includes("CookieExpired")) {
                    localStorage.removeItem("phone");
                    document.cookie.split(";").forEach(function (c) {
                        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
                    });
                    if (this.$route.name === "Login" || this.$route.name === "RecurringSuccess" || this.$route.name === "RecurringFailed" || this.$route.name === "ResetPassword" || this.$route.name === "Activation" || this.$route.name === "ActivationSuccess" || this.$route.name === "ActivationFailed" || this.$route.name == "TopupSuccess" || this.$route.name == "TopupFailed") return;
                    this.$router.replace("/login");
                }
                return config;
            },
            error => {
                // Do something with request error
                return Promise.reject(error);
            }
        );
    }

    checkLang() {
        let lang = localStorage.getItem("lang");
        if (lang) {
            switch (lang) {
                case "en":
                    localStorage.setItem("lang", "en");
                    this.$i18n.locale = "en";
                    dayjs.locale("en");
                    break;
                case "it":
                    localStorage.setItem("lang", "it");
                    this.$i18n.locale = "it";
                    dayjs.locale("it");
                    break;
            }
        } else {
            localStorage.setItem("lang", "it");
            this.$i18n.locale = "it";
            dayjs.locale("it");
        }
    }
}
