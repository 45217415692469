import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/login",
        name: "Login",
        component: () => import("@/views/Enter/RegistrationPage.vue"),
        meta: {
            layout: "auth-layout",
            title: "Login | WSC",
        },
    },
    {
        path: "/reset-password/:number",
        name: "ResetPassword",
        component: () => import("@/views/Enter/ResetPasswordPage.vue"),
        meta: {
            layout: "auth-layout",
            title: "Reset password | WSC",
        },
    },
];

export default routes;
