
import { Vue } from "vue-class-component";

export default class Notification extends Vue {
    showNotification = false;
    textNotification = "";
    typeNotification: "success" | "danger" = "danger";

    created() {
        (window as any).Notification = this;
    }

    show(text: any, type: "success" | "danger" = "danger", delay = 1000) {
        this.showNotification = true;
        this.textNotification = text;
        this.typeNotification = type;
        setTimeout(() => {
            this.showNotification = false;
        }, delay);
    }
}
