import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/activation",
        name: "Activation",
        component: () => import("@/views/Activation/ActivationPage.vue"),
        meta: {
            layout: "auth-layout",
            title: "Activation | WSC",
        },
    },
    {
        path: "/activation/success",
        name: "ActivationSuccess",
        component: () => import("@/views/Activation/ActivationSuccess.vue"),
        meta: {
            layout: "auth-layout",
            title: "Activation | WSC",
        },
    },
    {
        path: "/activation/failed",
        name: "ActivationFailed",
        component: () => import("@/views/Activation/ActivationFailed.vue"),
        meta: {
            layout: "auth-layout",
            title: "Activation | WSC",
        },
    },
];

export default routes;
