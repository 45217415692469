import en from "@/i18n/en.json";
import it from "@/i18n/it.json";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/it";

import { createI18n } from "vue-i18n";

const i18n = createI18n({
    locale: "it",
    messages: {
        en: en,
        it: it,
    },
});

dayjs.locale("it");

export default i18n;
