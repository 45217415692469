<template>
    <div class="page">
        <div class="page-wrapper">
            <Header />
            <div class="page-main">
                <!-- Output main content -->
                <div class="main">
                    <MobileHeader />
                    <div class="main-wrapper">
                        <div class="main-top">
                            <a class="cancel-btn btn-shadow" @click="SwitchLang()">
                                <span class="text">{{ $t("main.lang") }}</span>
                            </a>
                        </div>
                        <slot />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Vue } from "vue-class-component";

export default class Default extends Vue {
    SwitchLang() {
        let lang = localStorage.getItem("lang");
        if (lang) {
            switch (lang) {
                case "en":
                    localStorage.setItem("lang", "it");
                    this.$i18n.locale = "it";
                    break;
                case "it":
                    localStorage.setItem("lang", "en");
                    this.$i18n.locale = "en";
                    break;
            }
            location.reload();
        }
    }
}
</script>

<style scoped></style>
