import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/options",
        component: () => import("@/views/ServicesPage.vue"),
        meta: {
            title: "Extras and Services | WSC",
        },
    },
    {
        path: "/options/success",
        component: () => import("@/views/Status/SuccessStatus.vue"),
        meta: {
            title: "Extras and Services | WSC",
            layout: "topup-layout",
        },
    },
    {
        path: "/options/failed",
        component: () => import("@/views/Status/FailedStatus.vue"),
        meta: {
            title: "Extras and Services | WSC",
            layout: "topup-layout",
        },
    },
    {
        path: "/options/activation-failed",
        component: () => import("@/views/Status/FailActionActivate.vue"),
        meta: {
            title: "Extras and Services | WSC",
            layout: "topup-layout",
        },
    },
];

export default routes;
