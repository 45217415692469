import axios from "axios";
import { IOfferDetails } from "@/struct/offer-details/IOfferDetails";

export default class ApiApp {
    public static API_PATH = "";

    public static async CheckIsOTPEligible(number: string): Promise<any> {
        try {
            const result = await axios.post(`${ApiApp.API_PATH}/api/v2/MobileApi/isOTPEligible`, {
                UserName: number,
            });
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async SendOTPVerification(number: string, otp: string): Promise<any> {
        try {
            const result = await axios.post(`${ApiApp.API_PATH}/api/v2/MobileApi/otpVerification`, {
                UserName: number,
                OTP: Number(otp),
            });
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async VerifyRegistration(number: string, password: string): Promise<any> {
        try {
            const result = await axios.post(`${ApiApp.API_PATH}/api/v2/MobileApi/verifyRegistration`, {
                UserName: number,
                Password: password,
                ConfirmPassword: password,
            });
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async LogOut(): Promise<any> {
        try {
            const result = await axios.post(`${ApiApp.API_PATH}/api/MobileApi/logout?intentional=true`);
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async Login(phone: string, password: string): Promise<any> {
        try {
            const result = await axios.post(`${ApiApp.API_PATH}/api/MobileApi/login`, {
                UserName: phone,
                Password: password,
                IsResolved: true,
            });
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async ForgotPassword(phone: string): Promise<any> {
        try {
            const result = await axios.post(`${ApiApp.API_PATH}/api/MobileApi/forgotPass`, {
                UserName: phone,
            });
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async GetSubscriptionsData(): Promise<any> {
        try {
            const result = await axios.get(`${ApiApp.API_PATH}/api/MobileApi/subscriptionData`);
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async GetExternalConfig(): Promise<any> {
        try {
            const result = await axios.get(`${ApiApp.API_PATH}/api/MobileApi/externalConfig`);
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async GetReferCodes(): Promise<any> {
        try {
            const result = await axios.get(`${ApiApp.API_PATH}/api/Subscription/codes`);
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async CreateReferCode(): Promise<any> {
        try {
            const result = await axios.post(`${ApiApp.API_PATH}/api/Subscription/code`);
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async GetNotifications(): Promise<any> {
        try {
            const result = await axios.get(`${ApiApp.API_PATH}/api/MobileApi/getNotifications`);
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async ForgotPass(phone: string): Promise<any> {
        try {
            const result = await axios.post(`${ApiApp.API_PATH}/api/MobileApi/forgotPass`, {
                UserName: phone,
            });
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async ValidateOTPRecoverPass(phone: string, otp: string): Promise<any> {
        try {
            const result = await axios.post(`${ApiApp.API_PATH}/api/MobileApi/checkNCode`, {
                MSISDN: phone,
                Code: Number(otp),
            });
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async RecoverPass(phone: string, otp: string, password: string): Promise<any> {
        try {
            const result = await axios.post(`${ApiApp.API_PATH}/api/MobileApi/recoverPass`, {
                UserName: phone,
                SecretCode: Number(otp),
                Password: password,
                ConfirmPassword: password,
            });
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async CheckNotification(): Promise<any> {
        try {
            const result = await axios.get(`${ApiApp.API_PATH}/api/MobileApi/checkNotifications`);
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async VoucherPayment(voucher: string): Promise<any> {
        try {
            const result = await axios.post(`${ApiApp.API_PATH}/api/MobileApi/voucherPayment`, {
                VoucherCode: voucher,
            });
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async CommitPayment(amount: string): Promise<any> {
        try {
            amount = amount.split("€").join("");
            const result = await axios.post(`${ApiApp.API_PATH}/api/v2/MobileApi/commitDigitalPayment`, {
                Amount: Number(amount),
                ShouldRedirect: true,
            });
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async GetServices(): Promise<any> {
        try {
            const result = await axios.get(`${ApiApp.API_PATH}/api/Subscription/services`);
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async ActivateService(option_id: number): Promise<any> {
        try {
            const result = await axios.post(`${ApiApp.API_PATH}/api/MobileApi/activateOption`, {
                OptionId: option_id,
                ActionType: 1,
            });
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async SwapOption(option_id: number): Promise<any> {
        try {
            const result = await axios.post(`${ApiApp.API_PATH}/api/v2/MobileApi/swapOption`, {
                OptionId: option_id,
                ActionType: 7,
            });
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async GetSubOptions(): Promise<any> {
        try {
            const result = await axios.get(`${ApiApp.API_PATH}/api/MobileApi/getSubOptions`);
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async GetOptionsForChange(): Promise<any> {
        try {
            const result = await axios.get(`${ApiApp.API_PATH}/api/v2/MobileApi/getOptionsForChange`);
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async GetUsageHistory(date_from: string, date_to: string, type: string): Promise<any> {
        try {
            const result = await axios.get(`${ApiApp.API_PATH}/api/MobileApi/xdrs?XdrTypes=${type}&ToDate=${date_to}&FromDate=${date_from}`);
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async GetRecurringInfo(): Promise<any> {
        try {
            const result = await axios.get(`${ApiApp.API_PATH}/api/v2.0/MobileApi/bssPaymentInformation`);
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async EditOption(option_id: number, action_type: number): Promise<any> {
        try {
            const result = await axios.post(`${ApiApp.API_PATH}/api/MobileApi/editOptions`, {
                OptionId: option_id,
                ActionType: action_type,
            });
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async DeactivateOption(option_id: number): Promise<any> {
        try {
            const result = await axios.post(`${ApiApp.API_PATH}/api/MobileApi/deactivateOption`, {
                OptionId: option_id,
                ActionType: 2,
            });
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async CancelOption(option_id: number): Promise<any> {
        try {
            const result = await axios.post(`${ApiApp.API_PATH}/api/MobileApi/cancelOption`, {
                OptionId: option_id,
                ActionType: 6,
            });
            return result.data;
        } catch (e) {
            return false;
        }
    }

    public static async GetPublicOffersFromCRM(): Promise<IOfferDetails[] | boolean> {
        try {
            const result = await axios.get(`${process.env.VUE_APP_CRM_URL}/api/web-api/offer-details/get-list`);
            return result.data.response;
        } catch (e) {
            return false;
        }
    }
}
