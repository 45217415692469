
import { Vue } from "vue-class-component";

export default class Preloader extends Vue {
    visible = false;

    created() {
        (window as any).Preloader = this;
    }

    Show() {
        this.visible = true;
    }

    Hide() {
        this.visible = false;
    }
}
