import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-395d19b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "preloader-animation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lottie_animation = _resolveComponent("lottie-animation")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["preloader", { show: _ctx.visible }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_lottie_animation, {
        ref: "anim",
        "animation-data": require('@/assets/logo.json'),
        loop: true,
        "auto-play": true,
        speed: 1
      }, null, 8, ["animation-data"])
    ])
  ], 2))
}